<template>
  <div class="from_outer">
    <div>
      <el-form
        label-width="120px"
        :rules="rules"
        ref="formName"
        :model="formLabelAlign"
      >
        <el-form-item
          label="风险点标题："
          prop="name"
          :rules="[{ required: true, message: '请输入风险点标题' }]"
        >
          <el-input
            placeholder="请输入风险点标题"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.name"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="导致后果："
          prop="ext_danger"
          :rules="[{ required: true, message: '请输入导致后果' }]"
        >
          <el-input
            type="textarea"
            placeholder="请输入导致后果"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.ext_danger"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          label="风险成因："
          prop="ext_causes"
          :rules="[{ required: true, message: '请输入风险成因' }]"
        >
          <el-input
            type="textarea"
            placeholder="请输入风险成因"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.ext_causes"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          label="管控措施："
          prop="ext_controll"
          :rules="[{ required: true, message: '请输入管控措施' }]"
        >
          <el-input
            type="textarea"
            placeholder="请输入管控措施"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.ext_controll"
          ></el-input>
        </el-form-item>

        <el-form-item label="应急措施：">
          <el-input
            type="textarea"
            placeholder="请输入应急措施"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.ext_plans"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="请选择部门："
          :rules="[{ required: true, message: '请选择部门' }]"
          prop="department_id"
        >
          <Bepartment
            class="form-inp"
            ref="Bepartment"
            v-model="formLabelAlign.department_id"
          />
        </el-form-item>

        <el-form-item label="分类：" prop="cate_id">
          <el-cascader
            class="form-inp"
            v-model="formLabelAlign.cate_id"
            :options="bepartment_Data"
            :props="{
              checkStrictly: true,
              label: 'name',
              children: 'items',
              value: 'id',
            }"
            @change="dataChange"
          ></el-cascader>
        </el-form-item>

        <!-- <el-form-item label="检查方式：">
          <el-select
            v-model="formLabelAlign.check_type"
            placeholder="请选择检查方式"
            class="form-inp"
          >
            <el-option
              v-for="item in check_typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->

        <div style="display: flex">
          <el-form-item label="巡检周期：">
            <el-select
              style="width: 300px"
              v-model="formLabelAlign.check_times"
              placeholder="请选择巡检周期"
              class="form-inp"
            >
              <el-option
                v-for="item in check_times"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label-width="0px"
            v-if="formLabelAlign.check_times == 5"
            prop="times_days"
          >
            <el-input
              style="width: 200px"
              placeholder="请输入天数"
              size="mini"
              class="form-inp"
              v-model.number="formLabelAlign.times_days"
            >
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
        </div>

        <el-form-item label="评估标准：">
          <el-select
            v-model="formLabelAlign.level_type"
            placeholder="请选择评估标准"
            class="form-inp"
          >
            <el-option
              v-for="item in estimate_type"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="风险等级：">
          <el-select
            class="form-inp"
            v-model="formLabelAlign.level_color"
            placeholder="请选择风险等级"
          >
            <el-option
              v-for="item in color_level"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="考核分数："
          prop="score"
          :rules="[{ required: true, message: '考核分数不能为空' }]"
        >
          <el-input
            type="number"
            placeholder="考核分数"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.score"
          ></el-input>
        </el-form-item>

        <el-form-item label="风险源：">
          <!-- <div class="tag-sty">
            <div>
              <el-tag
                v-for="(tag, index) in formLabelAlign.tags_list"
                :key="index"
                style="margin: 0 5px 10px 0"
                closable
              >
                {{ tag.name }}
              </el-tag>
            </div>
          </div> -->
          <div>
            <el-button @click="label_show = true" type="primary"
              >导入风险源模板</el-button
            >
          </div>

          <div class="tag-sty">
            <div>
              <el-tag
                @close="on_tabclose(index)"
                closable
                v-for="(rick_tag, index) in risk_List"
                :key="index"
                style="margin: 0 5px 10px 0"
              >
                {{ rick_tag.name }}
              </el-tag>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="风险源标签：">
          <div class="tag-sty">
            <div>
              <el-tag
                v-for="(tag, index) in label_select"
                :key="index"
                style="margin: 0 5px 10px 0"
              >
                {{ tag.name }}
              </el-tag>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="警示图片：">
          <div class="tag-sty">
            <div
              class="img-div"
              v-for="(src, index) in caution_select"
              :key="index"
            >
              <el-image
                :src="src.icon"
                class="pop-img"
                closable
                :preview-src-list="[src.icon]"
              />
            </div>
            <uploadingimg v-model="img_List" />
          </div>
        </el-form-item>

        <!-- <el-form-item
          label="是否启用："
          prop="is_enable"
          :rules="[{ required: true, message: '请选择是否启用' }]"
        >
          <el-switch
            v-model="formLabelAlign.is_enable"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item> -->
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
      <!-- 风险源标签 -->
      <el-dialog
        width="80%"
        title="风险源模板导入"
        :visible.sync="label_show"
        show-close
      >
        <div class="from_submit">
          <el-form label-width="120px" :model="label_from" class="from">
            <el-form-item label="风险源名称：">
              <el-input
                placeholder="请输入风险源名称"
                size="mini"
                class="pop-inp"
                v-model="label_from.name"
              ></el-input>
            </el-form-item>
            <el-form-item label="风险标签：">
              <el-select v-model="tage_Listvalue" placeholder="请选择" multiple>
                <el-option
                  v-for="item in tage"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button
                @click="way_labelList(true)"
                type="primary"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button
                @click="on_reset(label_from)"
                icon="el-icon-refresh-left"
                >重置</el-button
              >
            </div>
          </div>
        </div>
        <el-table
          ref="labeltable"
          highlight-current-row
          height="300px"
          :data="label_List"
          :header-cell-style="{ background: '#f8f8f9' }"
        >
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="name" label="风险源名称"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link type="primary" @click="on_addlabel(scope.row)"
                >添加</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @current-change="on_change"
            :current-page="label_from.page"
            :page-size="label_from.page_size"
            :total="label_total"
          >
          </el-pagination>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  indexs,
  dangeroussaveInfo,
  dangeroussave,
  tagslistall,
} from "../../assets/request/api";
import uploadingimg from "../../components/uploading/uploading_img.vue";
import Bepartment from "../../components/Bepartment.vue";
export default {
  name: "risk_sourceredact",
  components: { Bepartment, uploadingimg },
  data() {
    let skyRuel = (rule, value, callback) => {
      if (!this.$tools.verify_sky(value)) {
        callback(new Error("请输入正确天数"));
      } else {
        callback();
      }
    };
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      estimate_type: [],
      color_level: [],
      check_times: [],
      check_typeList: [],
      formLabelAlign: {
        name: "",
        ext_controll: "",
        ext_danger: "",
        ext_plans: "",
        cate_id: "",
        department_id: "",
        is_enable: "",
        level_type: "",
        level_color: "",
        ext_causes: "",
        score: 0,
        check_times: "",
        check_type: "",
        times_days: "",
      },
      //风险源列表参数和列表
      label_List: [],
      label_from: {
        id: "",
        name: "",
        is_enable: "1",
        page_size: 10,
        page: 1,
        tag_id: "",
      },
      label_total: 0,
      label_show: false,
      //风险源列表
      risk_List: [],
      //当前选择的标签
      label_select: [],
      //当前选择的图片
      caution_select: [],
      bepartment_Data: [],
      //自己添加的图片
      img_List: [],
      //多选标签数组
      tage: "",
      tage_Listvalue: [],
      rules: {
        times_days: [
          { validator: skyRuel, trigger: "blur" },
          { required: true, message: "请输入天数" },
        ],
      },
    };
  },
  created() {
    let { id, type } = this.$route.query;
    this.way_message(id, type);
    this.way_labelList();
    this.tagList();
  },
  methods: {
    tagList() {
      tagslistall().then((res) => {
        this.tage = res.data.data;
      });
    },

    way_message(id, type) {
      dangeroussaveInfo({ id: id }).then((res) => {
        let {
          cate,
          info,
          estimate_type,
          color_level,
          check_times,
          check_type,
        } = res.data;
        this.bepartment_Data = cate;
        this.estimate_type = estimate_type;
        this.color_level = color_level;
        this.check_times = check_times;
        this.check_typeList = check_type;
        if (id) {
          if (!type) {
            this.id = id;
          }
          let { formLabelAlign } = this;
          let {
            name,
            ext_controll,
            ext_danger,
            ext_plans,
            cate_id,
            department_id,
            is_enable,
            level_type,
            level_color,
            ext_causes,
            score,
            check_times,
            pointList,
            ext_icons,
            check_type,
            times_days,
          } = info;
          this.$refs.Bepartment.bepartment_List = department_id;
          this.risk_List = pointList || [];
        
          if (ext_icons) {
            this.img_List = ext_icons.split(",");
          }
          formLabelAlign.name = name;
          formLabelAlign.ext_controll = ext_controll;
          formLabelAlign.ext_danger = ext_danger;
          formLabelAlign.ext_plans = ext_plans;
          formLabelAlign.cate_id = cate_id;
          formLabelAlign.department_id = department_id;
          formLabelAlign.is_enable = is_enable;
          formLabelAlign.level_type = level_type;
          formLabelAlign.level_color = level_color;
          formLabelAlign.ext_causes = ext_causes;
          formLabelAlign.score = score;
          formLabelAlign.check_times = check_times;
          formLabelAlign.check_type = check_type;
          formLabelAlign.times_days = times_days;

          this.type_name = "修改";
          this.way_ispitchlabel();
        }
      });
    },
    //风险源列表
    way_labelList(type) {
      if (type) {
        this.label_from.page = 1;
      }
      let { label_from, tage_Listvalue } = this;
      label_from.tag_id = tage_Listvalue.join(",");
      indexs(label_from).then((res) => {
        this.label_total = res.data.total;
        this.label_List = res.data.data;
      });
    },
    //删除风险源列表
    on_tabclose(index) {
      this.risk_List.splice(index, 1);
      this.way_ispitchlabel();
    },
    dataChange(event) {
      let events = event[event.length - 1];
      this.formLabelAlign.cate_id = events;
    },
    //过滤展示的风险源标签和图片的重复项
    way_ispitchlabel() {
      let { risk_List } = this;
      let label_List = [];
      let caution_List = [];
      if (risk_List.length != 0) {
        risk_List.map((item) => {
          if (item.icons_list && item.icons_list.length != 0) {
            item.icons_list.map((el) => {
              let iscaution = caution_List.some((e) => e.id == el.id);
              if (!iscaution) {
                caution_List.push(el);
              }
            });
          }
          if (item.tags_list.length && item.tags_list.length != 0) {
            item.tags_list.map((el) => {
              let istag = label_List.some((e) => e.id == el.id);
              if (!istag) {
                label_List.push(el);
              }
            });
          }
        });
      }
      this.label_select = label_List;
      this.caution_select = caution_List;
    },
    //添加标签
    on_addlabel(row) {
      console.log(row);
      let { risk_List } = this;
      console.log(risk_List);
      let is_join = risk_List.some((item) => {
        return item.id == row.id;
      });
      if (is_join) {
        this.$message({
          message: "当前风险源已添加，请勿重复添加",
          type: "warning",
        });
      } else {
        risk_List.push(row);
        this.$message({
          message: "添加风险源成功",
          type: "success",
        });
        let { ext_danger, ext_causes, ext_controll, ext_plans } =
          this.formLabelAlign;
        if (row.danger) {
          // let line_feed = "\r\n"
          // if(!ext_danger) =
          this.formLabelAlign.ext_danger =
            ext_danger + (ext_danger ? "\r\n" : "") + row.danger;
        }
        if (row.causes) {
          this.formLabelAlign.ext_causes =
            ext_causes + (ext_danger ? "\r\n" : "") + row.causes;
        }

        if (row.control) {
          this.formLabelAlign.ext_controll =
            ext_controll + (ext_danger ? "\r\n" : "") + row.control;
        }

        if (row.plans) {
          this.formLabelAlign.ext_plans =
            ext_plans + (ext_danger ? "\r\n" : "") + row.plans;
        }

        this.way_ispitchlabel();
      }
    },
    on_change(val) {
      this.label_from.page = val;
      this.way_labelList();
    },
    //删除图片
    on_imgdelete(index) {
      this.formLabelAlign.icons_list.splice(index, 1);
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { formLabelAlign, id, risk_List, img_List } = this;
          if (id) formLabelAlign.id = id;
          let points;
          if (risk_List && risk_List.length != 0) {
            points = risk_List
              .map((obj) => {
                return obj.id;
              })
              .join(",");
          }

          let ext_icons = img_List.join(",");
          formLabelAlign.points = points;
          formLabelAlign.ext_icons = ext_icons;

          dangeroussave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              setTimeout(function () {
                that.$router.go(-1);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 500px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  display: flex;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
/deep/ .el-input__inner {
  height: 32px;
  margin-top: 1px;
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  width: 100%;
  height: 100%;
}
.img-div {
  position: relative;
  margin: 0 10px 10px 0;
  width: 80px;
  height: 80px;
}

.sty-imgdelete {
  position: absolute;
  right: 0;
  top: 0;
  background: #e0e0e0;
  padding: 3px;
  color: #ffffff;
}
</style>